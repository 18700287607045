* {
    box-sizing: border-box;
}

:root {
    --blue: #a3deed;
    --darkblue: #21027A;
    --purple: #7f004f;
    --tan: #e5a889;
    --brown: #7f2a00;
    --badgebluelight: #8CCBDF;
    --badgebluedark: #315E76;
    --badgegreenlight: #91D2AD;
    --badgegreendark: #4F8A60;
    --badgebrownlight: #D1A590;
    --badgebrowndark: #6F312A;
    --badgepurplelight: #948EDB;
    --badgepurpledark: #2D316E;
    --badgepinklight: #C289C1;
    --badgepinkdark: #662253;
    --badgeolivelight: #C2C985;
    --badgeolivedark: #95985D;
    --badgeorangelight: #f9ddce;
    --badgeorangedark: #d15124;
    --badgeblack: #000;
    --badgesilver: #dcdcdc;
    --badgesilverlight: #f5f5f5;
    --badgepinklighter: rgba(194,137,193,0.5);
    --badgebluelighter: rgba(140,203,223, 0.5);
    --badgegreenlighter: rgba(145,210,173, 0.5);
    --badgechocolate: #442A01;
    --badgechocolatelight: rgba(68,42,1,0.5);
    --badgezodiac: #0C2749;
    --badgeyellow: #F2E678;
    --badgepink: #D88DBF;
    --badgegreylight: #D7DEDB;
    --badgelilac: #A08ADE;
    --lilacpink: #D08EF5;
    --badgegreydark: #4A494A;
    --duskyblue: #5F6C9A;
}

.purplePage {
    background-color: var(--purple);
    color: white;
}

.purple {
    background-color: var(--badgepurpledark);
    color: white;
    border: 2px solid var(--badgepurplelight);
}

.purple-light {
    background-color: var(--badgepurplelight);
    color: black;
}

.blue {
    background-color: var(--badgebluedark);
    color: white;
    border: 2px solid var(--badgebluelight);
}

.blue-light {
    background-color: var(--badgebluelight);
    color: black;
}

.blue-lighter {
    background-color: var(--badgebluelighter);
    color: black;
}

.darkblue {
background-color: var(--darkblue);
    color: white;
}

.green {
    background-color: var(--badgegreendark);
    color: white;
    border: 2px solid var(--badgegreenlight);
}

.green-light {
    background-color: var(--badgegreenlight);
    color: black;
}

.green-lighter {
    background-color: var(--badgegreenlighter);
    color: black;
}

.brown {
    background-color: var(--badgebrowndark);
    color: white;
    border: 2px solid var(--badgebrownlight);
}

.brown-light {
    background-color: var(--badgebrownlight);
    color: black;
}

.pink {
    background-color: var(--badgepinkdark);
    color: white;
    border: 2px solid var(--badgepinklight);
}

.pink-light {
    background-color: var(--badgepinklight);
    color: black;
}

.pink-lighter {
    background-color: var(--badgepinklighter);
    color: black;
}

.olive {
    background-color: var(--badgeolivedark);
    color: white;
    border: 2px solid var(--badgeolivelight);
}

.olive-light {
    background-color: var(--badgeolivelight);
    color: black;
}

.orange {
    background-color: var(--badgeorangedark);
    color: black;
}

.orange-light {
    background-color: var(--badgeorangelight);
    color: black;
}

.black {
    background-color: var(--badgeblack);
    color: white;
}

.silver {
    background-color: var(--badgesilver);
    color: #000;
}

.silver-light {
    background-color: var(--badgesilverlight);
    color: #000;
}

.chocolate {
    background-color: var(--badgechocolate);
    color: #fff;
}

.chocolate-light {
    background-color: var(--badgechocolatelight);
    color: #000;
}

.yellow {
    background-color: var(--badgeyellow);
    color: #000;
}

.yellow-light {
    background-color: var(--badgeyellow);
    color: #000;
}

.lilac {
    background-color: var(--badgelilac);
    color: #000;
}

.pinknew {
    background-color: var(--badgepink);
    color: #000;
}

.lightgrey {
    background-color: var(--badgegreylight);
    color: #000;
}

.grey {
    background-color: var(--badgegreydark);
    color: #fff;
}

.duskyblue {
    background-color: var(--duskyblue);
    color: #fff;
}

.lilacbright {
    background-color: var(--lilacpink);
    color: #000;
}

html,
body,
main,
header,
footer {
    display: block;
    position: relative;
    width: 100vw;
    margin: 0;
    padding: 0;
}

body {
    font-size: 1rem;
    line-height: 1.5;
    font-family: 'Signika';
}

p,
a,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    margin: 0;
}

header {
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    color: #fff;
}

footer {
    width: 100%;
    background-color: black;
    color: white;
    padding: 1rem;
    margin-top: 2rem;
}

.row, .evRow {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    gap:1%;
}

.row,
.row.site-header {
    margin-left: auto;
    margin-right: auto;
}

.row.site-header {
    width: 90%;
}

.site-logo img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}


@media (max-width: 768px) 
{
  .site-logo img
  {
      width: 90px;
      height: 90px;
  }
}


.site-logo {
    width: 25%;
}

.site-cover h1 {
    font-family: "Trade Winds";
    font-size: 60px;
    text-align: center;
}

.site-cover h1
{
  font-family: 'Trade Winds';
  font-size: 60px;
  text-align:center;
}

@media (max-width: 886px)
{
  .site-cover h1
  {
      font-size: 20px;
      margin:0;
      padding:0;
  }
}

.site-cover {
    width: 50%;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.site-social {
    width: 25%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 30px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.site-social a {
    color: #fff;
}

.rebelLeft {
    width: 24%;
    text-align: left;
}

.rebelMid {
    width: 49%;
    text-align: center;
    align-self: center;
}

.rebelMid h2 {
font-size: 1.5rem;
}

@media(min-width:1024px) {
    .rebelMid H2 {
        font-size: 2.5rem;
    }
}

.rebelRight {
    width: 24%;
    text-align: right;
}

img.rebelLogoLeft,
img.rebelLogoBookLeft {
    width: 20rem;
    max-width: 100%;
    height: auto;
    transform: rotate(-25deg);
}

img.rebelLogoRight,
img.rebelLogoBookRight {
    width: 20rem;
    max-width: 100%;
    height: auto;
    transform: rotate(25deg);
}

img.rebelLogoBookLeft,
img.rebelLogoBookRight {
    width: 10rem;
}

.rebelCard {
    width: 100%;
}

.rebelCardBook {
    width: 100%;
    height: 100%;
}

.rebelCardFull {
    width: 100%;
    margin-top: 2rem;
}

.rebelCardHalf {
    width: 100%;
    margin-top: 2rem;
}


.rebelCard a, .rebelCard.patch {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    color: inherit;
    box-shadow: 0 8px 16px 0 rgb(127 0 79 / 20%), 0 12px 40px 0 rgb(127 0 79 / 19%);
}

@media (min-width: 568px) {
    .rebelCard {
        width: calc(100% / 5 - 1%);
    }

    .rebelCardFull {
        width: 100%;
    }

    .rebelCardFull.patch {
        width: 49%;
        margin-top: 2rem;
    }

    .rebelCardHalf {
        width: 49%;
    }
}

@media (min-width: 1024px) {
    .rebelCard.patch{
        margin-top: 2rem;
    }
}

.rebelCardTitle {
    text-align: center;
    height: 3rem;
}

.rebelCardTitle h3 {
    font-size: 1.2rem;
    font-weight: bold;
}

.rebelCardImage, .rebelChartImage {
    text-align: center;
    margin-top: 1rem;
}

.rebelChartImage {
    width: 50%;
}

.rebelCardImage img {
    width: 50%;
    height: auto;
}

.rebelChartImage img {
    width: 100%;
    height: auto;
}

@media (min-width: 568px) {
    .rebelCardImage img, .rebelChartImage img {
        width: 100%;
    }
}

.rebelCardImage img.Chart, .rebelChartImage img.Chart {
    width: 100%;
    height: auto;
}

@media(min-width: 568px) {
    .rebelCardImage img.Chart {
        width: 60%;
    }
    
}

.rebelCardImage img.imgThird, .rebelChartImage img.imgThird {
    width: 30%;
    height: auto;
}

.bookPageTop {
    border-bottom: 1px solid var(--purple);
    box-shadow: 0 4px 8px 0 rgb(127 0 79 / 45%);
}

.pageIntroduction, .pageText {
    margin-bottom: 2rem;
    font-size: 1.1rem;
}

.pageText {
    font-size: 0.9rem;
}

.pageText a {
    text-decoration: underline;
}

.badgeItem,
.mavItem {
    width: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
    border: 1px solid black;
    margin-bottom: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (min-width: 1024px) {
    .badgeItem,
    .mavItem {
        width: 90%;
    }
}

.badgeItemTitle,
.mavItemTitle {
    width: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .badgeItemTitle,
    .mavItemTitle {
        font-size: 1.2rem;
    }
}

.badgeItemTitle div:first-of-type,
.mavItemTitle div:first-of-type {
    width: 50%;
}

.badgeItemTitle div:last-of-type,
.mavItemTitle div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}

@media (min-width: 1024px) {
    .badgeItemTitle div:last-of-type,
    .mavItemTitle div:last-of-type {
        width: auto;
        min-width: 50%;
    }
}

.badgeItemTitle progress,
.mavItemTitle progress {
    margin-right: 1rem;
    height: 0.5rem;
}
    
@media (min-width: 1024px) {
    .badgeItemTitle progress,
    .mavItemTitle progress {
        margin-right: 2rem;
        height: 1rem;
    }
}

.badgeItemIntro,
.mavItemIntro {
    padding: 0.5rem;
    font-weight: bold;
    justify-content: space-between;
    width: 100%;
}

.badgeClause,
.mavClause {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem;
}
    
@media (min-width:1024px) {
    .badgeClause,
    .mavClause {
        padding: 1rem;  
    }
}

.badgeClauseTask, .mavClauseTask {
    width: 100%;
    font-weight: bold;
}

@media(min-width: 1024px) {
.badgeClauseTask, .mavClauseTask {
    width: 20%;
    }
}

.mavClauseStatus, .mavClauseStart, .mavClauseEnd {
    width: 32%;
}

.badgeClauseStatus, .badgeClauseStart, .badgeClauseEnd {
    width: 32%;
}

.badgeClauseProgress, .mavClauseProgress {
    width: 100%;
}

@media (min-width: 568px) {

    .mavClauseStatus, .mavClauseStart, .mavClauseEnd {
        width: 10%;
    }

    .badgeClauseStatus, .badgeClauseStart, .badgeClauseEnd {
        width: 15%;
    }

    .badgeClauseProgress, .mavClauseProgress {
        width: 35%;
    }
}
                
progress {
    width: 100%;
}

progress.red {
    background-color: red;
    border: 1px solid #fff;
    color: red;
}

progress.red[value] {
    color: red
}

/* IE10 */
progress.red::-webkit-progress-bar-value {
    background-color: red
}

progress.red::-webkit-progress-value {
    background-color: red
}

progress.red::-moz-progress-bar {
    background-color: red
}

progress.orange {
    background-color: orange;
    color: orange;
}

progress.orange[value] {
    color: orange
}

/* IE10 */
progress.orange::-webkit-progress-bar-value {
    background-color: orange;
    border: 1px solid #fff;
}

progress.orange::-webkit-progress-value {
    background-color: orange
}

progress.orange::-moz-progress-bar {
    background-color: orange
}

progress.green {
    background-color: green;
    border: 1px solid #fff;
    color: green;
}

progress.green[value] {
    color: green
}

/* IE10 */
progress.green::-webkit-progress-bar-value {
    background-color: green
}

progress.green::-webkit-progress-value {
    background-color: green
}

progress.green::-moz-progress-bar {
    background-color: green
}

.badgeProgress,
.mavProgress {
    width: 100%;
    position: relative;
    display: block;
    height: 2rem;
}

.badgeProgress progress,
.mavProgress progress {
    width: 100%;
    height: 100%;
}

.badgePageItem, .mavPageItem {
    width: 90%;
    margin: 0 auto;
    display: block;
    position: relative;
    border: 1px solid black;
    margin-bottom: 2rem;
}

.badgePageItemTitle, .mavPageItemTitle {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.badgePageItemTitle div:last-of-type, .mavPageItemTitle div:last-of-type  {
    display: flex;
    align-items: center;
}

.badgePageItemInfo {
    width: 100%;
    padding: 0.5rem;
}

.badgePageItemEvidence, .mavPageItemEvidence {
    width: 100%;
    padding: 0.5rem;
}

table {
    width: 90%;
    margin: 0 auto 2rem auto;
    border-collapse: collapse;
}

table td {
    border: 1px solid black;
}

.actionBar {
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.actionBarItem {
    background-color: var(--badgepurplelight);
    padding: 0.5rem;
    cursor: pointer;
}

.actionBarItem:hover {
    background-color: var(--purple);
    color: white;
}

.mavInline {
    width: 50%;
    height: auto;
}

.mavHeading {
    font-size: 1.2rem;
}

.mavList {
    margin-left: 2rem;
}

.mavPageItem a {
    color: darkblue;
    text-decoration: underline;
}

.extrasrow, .extrastext {
    width: 90%;
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.extrastext {
    font-size: 1rem;
    font-weight: normal;
    padding: 0 0 1rem 0;
}


.hRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;
}

.hRow img {
  max-width:  24%;
  height: auto;
}

.halloweenTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.halloweenTable tr th:first-of-type {
  width: 20%;
  text-align: left;
}

.halloweenTable tr th:last-of-type {
  width: 80%;
  text-align: center;
  vertical-align: top;
  padding-top: 2rem;
  font-size: 2.5rem;
  font-family: Bodoni MT Black;
}

.halloweenTable thead tr:last-of-type th:last-of-type {
  font-family: Signika;
  font-size: 1rem;
  line-height: 1.5;
}

.halloweenTable th, .halloweenTable td {
  border: 0;
}

.halloweenTable img {
  width: 100%;
  height: auto;
}

.halloweenTable img.hSmall {
  width: 50%;

}


ul.listWrite {
    margin-left: 2rem;
}

table.tblWrite th {
    text-align:left;
}

table.tblWrite td:first-of-type {
    width: 5%;
    padding: 0.5rem;
    background-color: #d3d3d3;
    text-align: center;
}

table.tblWrite tbody tr td:last-of-type {
    padding-left: 1rem;
}

table.tblWrite tbody tr td:last-of-type::first-letter {
    font-weight: bold;
    text-decoration: underline;
}

#bakerPage table th:nth-child(1), #bakerPage table td:nth-child(1) {
    width: 10%;
}

#bakerPage table th:nth-child(2), #bakerPage table td:nth-child(2) {
    width: 10%;
}

#bakerPage table th:nth-child(3), #bakerPage table td:nth-child(3) {
    width: 80%;
}

#bakerPage img {
    width: 100%;
    height: auto
}

.linkUnderline {
    text-decoration: underline;
}

.hidden {
    display: none;
}

.shown {
    display: flex;
}

.shownBlock {
    display: block;
}

.evRow {
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.evRowGap {
    justify-content: flex-start;
    gap: 1%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.evDate {
    width: 100%;
}

.evImage {
    width: 100%;
}

.evImage img {
    width: 100%;
    height: auto;
}

.evContent {
    width: 100%;
}

@media (min-width: 568px) {
    .evImage {
        width: 30%;
    }
    .evDate {
        width: 20%;
    }

    .evContent {
        width: 45%;
    }
}

@media (min-width: 768px) {
    .evDate {
        width: 15%;
    }

    .evContent {
        width: 50%;
    }
}

.adventureGallery, .activistGallery, .chefGallery, .moneyGallery, .styleGallery, .readerGallery, .gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.gallery {
    justify-content: left;
    gap: 1%;
}

.adventureGalleryItem, .galleryItem {
    width: 30%;
    display: block;
    margin-bottom: 2rem;
}

.adventureGalleryItemImage, .adventureGalleryItemDesc {
    width: 100%;
    height: auto;
}


.adventureImg, .beliefsImg {
    width: 30%;
    height: auto;
}

.beliefsImgLarger {
    width: 45%;
    
}

h3.bronze {
    font-size: 1.3rem;
    margin: 1rem 0;
}

.challengeStatus {
    height: 2rem;
    background-color: green;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.challengeStatusIP {
    background-color: goldenrod;
}

.challengeStatusP {
    background-color: darkred;
}

.challengeGallery, .skillsGallery {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: flex-start;
    gap: 1%;
    align-items: flex-start;
}

.challengeImg {
    width: 30%;
    height: auto;
    margin-bottom: 1rem;
}

ul.challengeList {
    padding: 0;
    margin: 1rem;
}

.imgThird {
    width:30%;
    height: auto;
}

.imgQuarter {
    width:23%;
    height: auto;
    margin-bottom: 1rem;
}

.imgHalf {
    width: 50%;
    height: auto;
}

.patchRow, .patchItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 768px)
{
    .patchRow {
        align-items: flex-start;
    }
}

.patchItem {
    width: 100%;
    justify-content: flex-start;
    gap:0.5rem;
}

@media (min-width: 768px)
{
    .patchItem {
        width: calc(100% / 6);
        justify-content: flex-start;
        gap:0.2rem;
        flex-direction: column;
    }
}

@media (min-width: 1024px)
{
    .patchItem {
        width: calc(100% / 6);
        justify-content: flex-start;
        gap:0.2rem;
    }
}

.square {
    width: 2rem;
    height: 2rem;
}

@media (min-width: 768px)
{
    .square {
        width: 1rem;
        height: 1rem;
        margin: 0 auto;
    }
}

@media (min-width: 1024px)
{
    .square {
        width: 1rem;
        height: 1rem;
    }
}

.Chart {
    width: 50%;
    height: auto;
}

#tblActivist {
    width: 100%;
    border-collapse: collapse;
}

#tblActivist th, #tblActivist td {
    padding: 0.2rem;
}

#tblActivist th {
    font-weight: bold;
    border: 1px solid black;
}

#tblActivist td img {
    width: 200px;
    height: auto;
}

.list {
    margin-left: 2rem;
}

.list li {
    word-break: break-all;
    max-width: 100%;
    padding: 0;
    margin: 0;
    text-wrap: wrap;
}

.activistItem {
    width: 20%;
}

.activistItem img {
    width: 90%;
    height: auto;
}

.activistLink {
    font-weight: bold;
    text-decoration: underline;
}

.chefGallery, .moneyGallery, .styleGallery, .readerGallery {
    justify-content: flex-start;
    gap: 1%;
}

.chefItem, .moneyItem, .styleItem, .readerItem {
    width: 19%;
}

.chefItem  img, .moneyItem img, .styleItem img, .readerItem img {
    width: 95%;
    height: auto;
}

.tblCipher {
    width: 40%;
    margin: 0;
    text-align: center;
}

.bookSectionTitle {
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    text-shadow: 1px 1px 1px #000,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
        1px 18px 6px rgba(16,16,16,0.4),
        1px 22px 10px rgba(16,16,16,0.2),
        1px 25px 35px rgba(16,16,16,0.2),
        1px 30px 60px rgba(16,16,16,0.4);
    margin-bottom: 1rem;
}

.bookSectionTitle.bstpurple {
    color: var(--badgepurpledark);
}

.bookSectionTitle.bstpink {
    color: var(--badgepinkdark);
}

.bookSectionTitle.bstolive {
    color: var(--badgeolivedark);
}

.bookSectionTitle.bstblue {
    color: var(--badgebluedark);
}

.bookSectionTitle.bstgreen {
    color: var(--badgegreendark);
}

.bookSectionTitle.bstbrown {
    color: var(--badgebrowndark);
}

.reverse {
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
    -o-transform:rotateY(180deg);
    -ms-transform:rotateY(180deg);
    transform:rotateY(180deg);
    unicode-bidi:bidi-override;
    direction:rtl;
}

quote {
    font-weight: bold;
    font-style: italic;
}

.centre {
    margin: 0 auto;
    text-align: center;
}

.bronzeIntro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.pageIntro {
    border: 0;
}

@media print {
    .pdf-container {
        display: none;
    }
}

iframe {
    pointer-events:none;
}

.rebelCardRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1%;
}

.rebelCardStats, .rebelCardStats-Full {
    width: 100%;
    margin: 1rem 0;
    border: 1px solid grey;
}

.rebelCardStats-Full {
    width: 100%;
}

@media (min-width: 568px) {
    .rebelCardStats {
        width: 31%;
    }
}

.statsText {
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #dcdcdc;
    text-align: center;
    border-bottom: 1px solid grey;
}

.statsNumber {
    text-align: center;
    font-size: 3rem;
}

.statsTextDark {
    background-color: #0C2749;
    color: #fff;
}

.extrasYear {
    width: 90%;
    margin: 0 auto 1rem auto;
    cursor: pointer;
}

.extrasYear-Title {
    width: 100%;
    padding: 0.5rem;
    background-color: var(--badgesilver);
    border: 1px solid #000;
    font-weight: bold;
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    align-content:center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

blockquote {
    width: 90%;
    margin: 1rem auto;
    font-size: 1.2rem;
    font-style: italic;
}

.genreSection {
    width: 95%;
    margin: 0 auto;
    border: 2px solid var(--purple);
    padding: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.goalsIntro, .singerIntrop {
    padding: 1rem;
    font-size: larger;
    text-align: center;
    border: 1px solid black;
    margin-bottom: 1rem;
}