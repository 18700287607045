.box
{
    width: 100%;
    padding: 3rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #f1e7e7;
}
.loginForm
{
    width: 50%;
    margin: 0 auto;
}

.loginFormBody
{
    width: 100%;
}

label
{
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.7rem;
}

input[type="text"], input[type="password"]
{
  height: 2rem;
  border: 1px solid black;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

button, .linkButton
{
    width: 20%;
    height: 2rem;
    background-color: #2F242C;
    border: 1px solid black;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
}

.linkButton
{
    padding: 1rem;
}

button:active
{
    background-color: #f1e7e7;
    color: #4C0027;
    font-weight: bold;
}

.register
{
    width: 50%;
    margin: 0 auto;
}

.registerContent
{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 1rem 0;
}

.register button
{
    width: 35%;
}

.register button:active
{
    background-color: #fff;
    color: #2F242C;
}

.buttonLight
{
    background-color: transparent;
    border: none;
}
.acLine {
    width: 100%;
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.tlGreen,
.tlRed {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.tlGreen {
    background-color: green;
}

.tlRed {
    background-color: red;
}

.acName {
    width: calc(100% - 20px);
}

.visible {
    visibility: visble;
    display: block;
}

.visuallyhidden {
    visibility: hidden;
    display: none;
}
