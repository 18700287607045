*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body, header, main, footer
{
  width: 100%;
  display: block;
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
}

.container
{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
}

a
{
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: inherit;
  color: inherit;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

main
{
  min-height: calc(100vh - (3.5rem + 2rem));
}

.appHeader 
{
  background-color: #4C0027;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: hsla(316, 13%, 16%, 1);
  background: linear-gradient(135deg, hsla(316, 13%, 16%, 1) 0%, hsla(316, 13%, 16%, 1) 0%, hsla(329, 100%, 15%, 1) 30%, hsla(329, 100%, 15%, 1) 70%, hsla(316, 13%, 16%, 1) 100%);
  background: -moz-linear-gradient(135deg, hsla(316, 13%, 16%, 1) 0%, hsla(316, 13%, 16%, 1) 0%, hsla(329, 100%, 15%, 1) 30%, hsla(329, 100%, 15%, 1) 70%, hsla(316, 13%, 16%, 1) 100%);
  background: -webkit-linear-gradient(135deg, hsla(316, 13%, 16%, 1) 0%, hsla(316, 13%, 16%, 1) 0%, hsla(329, 100%, 15%, 1) 30%, hsla(329, 100%, 15%, 1) 70%, hsla(316, 13%, 16%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2F242C", endColorstr="#2F242C", GradientType=1 );
  height: 3.5rem;
}

.navbar
{
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding: 1rem 0;
}

.navbarBrand
{
  width: 25%;
  text-align: left;
}

.navbarMenu
{
  width: auto;
  text-align: right;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.appMain {
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
  }
  
  @media (min-width: 1024px) {
    .appMain {
      width: 75%;
    padding: 5rem 0;
  
}
}
footer
{
  background-color: #4C0027;
  color: #fff;
}

.appFooter
{
  width: 75%;
  margin: 0 auto;
  min-height: 2rem;
  display: flex;
  align-items: center;
}

.navbar-start, .navbar-end
{
  width: auto;
}

.navbarButton
{
  width: 100%;
}


nav img
{
  width: auto;
  height: 3rem;
  filter: invert(1);
}


img {
  width: 100%;
  height: auto;
}